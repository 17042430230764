import { Observable } from 'rxjs';

export function createInterest$(resourceId: string) {
  let c = 0;
  return new Observable<string>((o) => {
    const channel = new BroadcastChannel(resourceId);
    c++;
    channel.onmessage = (event: MessageEvent<string>) =>
      o.next(`${resourceId} ${c} ${event.data}`);
    return () => channel.close();
  });
}

export function indicateInterest(resourceId: string, note?: string) {
  const channel = new BroadcastChannel(resourceId);
  // https://jira.refinitiv.com/browse/WETOK-1073
  const channelMonitor = new BroadcastChannel(resourceId);
  channelMonitor.onmessage = () => {
    channel.close();
    channelMonitor.close();
  };
  channel.postMessage(note || '×');
}
