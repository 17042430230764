import { from, of } from 'rxjs';
import { estimatedNowAtServerMs } from './estimated-now-at-server.js';
import { deleteItemFromDb, getItemFromDb, setItemToDb } from './indexed-db.js';
import { toBase64Url, uuidv4 } from './jwt-helper.js';
import {
  exportJwk,
  generateCryptoKeyPair,
  sha256,
  signWithKey,
} from './web-crypto.js';

const DPOP_KEY_NAME = 'heimdall-key-pair';

async function getKeyPair(): Promise<CryptoKeyPair> {
  const storedKeyPair = await getStoredKeyPair();
  if (storedKeyPair) {
    return storedKeyPair;
  } else {
    return storeNewKeyPair();
  }
}

async function getStoredKeyPair(): Promise<CryptoKeyPair | null> {
  return getItemFromDb<CryptoKeyPair>(DPOP_KEY_NAME);
}

async function storeNewKeyPair(): Promise<CryptoKeyPair> {
  const newKeyPair = await generateCryptoKeyPair();
  await setItemToDb(DPOP_KEY_NAME, newKeyPair);
  return newKeyPair;
}

async function createDPoPJWT(
  keyPair: CryptoKeyPair,
  targetUrl: string,
  httpMethod: string,
  token?: string
) {
  const jwk = await exportJwk(keyPair.publicKey);
  const tokenHash = token ? await sha256(token) : undefined;
  delete jwk.ext;
  delete jwk.key_ops;
  const header = {
    typ: 'dpop+jwt',
    alg: 'RS256',
    jwk,
  };
  const payload = {
    jti: uuidv4(),
    htm: httpMethod.toUpperCase(),
    htu: targetUrl,
    iat: Math.floor(estimatedNowAtServerMs() / 1000),
    ath: tokenHash ? toBase64Url(tokenHash) : undefined,
  };

  const encodedHeader = toBase64Url(JSON.stringify(header));
  const encodedPayload = toBase64Url(JSON.stringify(payload));
  const dataToSign = `${encodedHeader}.${encodedPayload}`;
  const signature = await signWithKey(keyPair.privateKey, dataToSign);
  const encodedSignature = toBase64Url(
    String.fromCharCode.apply(null, Array.from(new Uint8Array(signature)))
  );
  const dpopJwt = `${encodedHeader}.${encodedPayload}.${encodedSignature}`;
  return dpopJwt;
}

export async function generateDpopHeader(
  targetUrl: string,
  httpMethod: string,
  token?: string
): Promise<string> {
  const keyPair = await getKeyPair();
  const dpopJwt = await createDPoPJWT(
    keyPair,
    targetUrl,
    httpMethod,
    token ? token : undefined
  );
  return dpopJwt;
}

export function generateDpopHeader$(
  targetUrl: string,
  httpMethod: string,
  token?: string
) {
  return from(generateDpopHeader(targetUrl, httpMethod, token));
}

export function generateDpopHeaderIfRequired$(
  isRequired: boolean,
  targetUrl: string,
  httpMethod: string,
  token?: string
) {
  return isRequired
    ? generateDpopHeader$(targetUrl, httpMethod, token)
    : of('');
}

export async function deleteKeyPair() {
  return deleteItemFromDb(DPOP_KEY_NAME);
}

export function rotateDpopKeys$() {
  return from(deleteKeyPair().then(storeNewKeyPair));
}
