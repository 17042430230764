import { SwapTokenType, TokenAudience } from './internal.js';

export function createTokenSwapParams(
  subjectToken: string,
  clientUid: string,
  swapTokenType: SwapTokenType,
  audiences?: (TokenAudience | 'stateless')[]
) {
  const paramArgs = {
    subject_token: subjectToken,
    grant_type: 'token-exchange',
    subject_token_type: swapTokenType,
    client_uid: clientUid,
    ...(audiences ? { aud: [...new Set(audiences)].join(' ') } : {}),
  };

  return new URLSearchParams(paramArgs);
}

export function createDeviceTakeOverParams(
  authToken: string,
  codeVerifier: string
) {
  return new URLSearchParams({
    auth_token: authToken,
    code_verifier: codeVerifier,
    grant_type: 'auth_token',
  });
}
