import { Observable, of, switchMap } from 'rxjs';
import { shareLast, createRetryFetch$ } from '@heimdall/client';

const searchParams = new URLSearchParams(document.location.search);
const appOrigin = document.location.origin;
const issuerUrls = {
  dev: 'https://wetok-dev-cdn.aws-4-dev-test-workspace-evolution.public.inf0.net/auth/v1',
  qa: 'https://token.qa-workspace.t-lseg.com/auth/v1',
  ppe: 'https://token.ppe-workspace.t-lseg.com/auth/v1',
  prod: 'https://token.workspace.lseg.com/auth/v1',
};
const clientUid = (searchParams.get('clientUid') || '').trim();
const loginHint = searchParams.get('loginHint') || undefined;
const forceRedirect = searchParams.get('forceRedirect') === 'true';
const webLoginUrl = location.origin + import.meta.env.BASE_URL + '/index.html';
const teamsLoginUrl =
  location.origin + import.meta.env.BASE_URL + '/teams/index.html';
const redirectUri =
  location.origin + import.meta.env.BASE_URL + '/redirect/index.html';
const linkRedirectUri =
  location.origin + import.meta.env.BASE_URL + '/redirect/link.html';

const linkOriginKey = searchParams.get('klo') || undefined;

const nextPathParam = (searchParams.get('nextPath') || '').trim();
const nextPath =
  nextPathParam.indexOf('#') === -1
    ? nextPathParam + document.location.hash
    : nextPathParam;
const postLogoutUrl =
  location.origin + import.meta.env.BASE_URL + '/logout/index.html';

type Env = 'dev' | 'qa' | 'ppe' | 'prod';

type EnvFile = {
  environment: Env;
};

export type AppConfig = {
  issuerUrl: string;
  clientUid: string;
  redirectUri: string;
  linkRedirectUri: string;
  linkOriginKey?: string;
  nextPath: string;
  webLoginUrl: string;
  teamsLoginUrl: string;
  postLogoutUrl: string;
  forceRedirect: boolean;
  loginHint?: string;
  env: Env;
};

const appConfig$: Observable<AppConfig> = createRetryFetch$<EnvFile>(
  '/public/env.json'
).pipe(
  switchMap(({ environment: env }) => {
    return of({
      issuerUrl: issuerUrls[env],
      clientUid,
      redirectUri,
      linkRedirectUri,
      linkOriginKey,
      nextPath,
      webLoginUrl,
      teamsLoginUrl,
      postLogoutUrl,
      forceRedirect,
      loginHint,
      env,
    });
  }),
  shareLast
);

function isNextPathValid(nextPath: string): boolean {
  return !!nextPath && new URL(nextPath, appOrigin).origin === appOrigin;
}

export { appConfig$, isNextPathValid };
