type TokenPayload = {
  exp?: number;
  client?: string;
  devc?: string;
  aud?: string;
  scope?: string;
  iat?: number;
};

export function decodeJwtPayload(jwt: string): TokenPayload {
  const payloadB64Url = jwt.split('.')[1];
  const payload = fromBase64Url(payloadB64Url);
  return JSON.parse(payload);
}

export function fromBase64Url(base64input: string): string {
  return atob(base64input.replace(/-/g, '+').replace(/_/g, '/'));
}

export function toBase64Url(input: string): string {
  return btoa(input).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

export function uuidv4() {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );
}
