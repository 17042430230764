import {
  alignLocalStorageValue,
  getLocalStorageValue,
} from './local-storage.js';

export function observedServerTimeMs(time: number) {
  const now = Date.now();
  const serverDeltaMs = now - time;
  alignLocalStorageValue(
    'heimdall:observed-server-drift-milliseconds',
    Math.round(serverDeltaMs).toString()
  );
}

export function estimatedNowAtServerMs() {
  return Date.now() - estimatedServerDeltaMs();
}

export function estimatedServerDeltaMs() {
  const stored = getLocalStorageValue(
    'heimdall:observed-server-drift-milliseconds'
  );
  if (!stored) return 0;
  const milliseconds = parseInt(stored);
  if (isNaN(milliseconds)) return 0;
  return milliseconds;
}
