import { app, authentication } from '@microsoft/teams-js';
import type { Observable } from 'rxjs';
import { from } from 'rxjs';

export function getTeamsToken$(): Observable<string> {
  return from(
    app.initialize().then(() => {
      return authentication.getAuthToken().then((teamsToken) => {
        return teamsToken;
      });
    })
  );
}
