import {
  Observable,
  Subscription,
  filter,
  map,
  of,
  startWith,
  switchMap,
  switchScan,
  timer,
} from 'rxjs';
import { createDistributedSubject$ } from './distributed-observable.js';
import {
  DpopMode,
  TokenBundle,
  TokenBundleHeimdall,
  TokenOwner,
  TokenSet,
  TokenSetHeimdall,
  isAudience,
  isEndpointRefreshInfo,
  isHeimdallTokenSet,
  isIdpBundle,
} from './internal.js';
import {
  isFreshTokenBundle,
  packNativeTokenBundleIdp,
  packWebTokenBundleIdp,
} from './token-time.js';
import { decodeJwtPayload } from './jwt-helper.js';
import { getDebugRefreshOverrideMs } from './local-storage.js';
import { getTeamsToken$ } from './ms-teams-token.js';
import { createRetryFetch$, dither, flog, msToMin } from './rx-helpers.js';
import { estimatedNowAtServerMs } from './estimated-now-at-server.js';

const expireByRefreshMarginMs = dither(25_000, 60_000);

export const notifyRefresh$ = createDistributedSubject$<string>(
  'heimdall:notify-refresh'
);

export function keepTokenFresh(
  owner: TokenOwner,
  refreshOnNotification = false,
  dpopMode?: DpopMode
) {
  return (source$: Observable<TokenBundle | null>) =>
    source$.pipe(
      switchMap((tokenBundle: TokenBundle | null) => {
        if (!tokenBundle) return of(tokenBundle);

        if (!isFreshTokenBundle(tokenBundle)) {
          return of(tokenBundle).pipe(
            flog(`Expired token for ${owner} can't be refreshed`)
          );
        }

        return new Observable<TokenBundle>((o) => {
          const subscription = new Subscription();
          subscription.add(
            notifyRefresh$
              .pipe(
                filter(() => refreshOnNotification),
                startWith(''),
                switchScan((bundle: TokenBundle, notificationReference) => {
                  const instance$ = new Observable<TokenBundle>((o) => {
                    let lastBundle: TokenBundle = bundle;
                    let immediate = !!notificationReference;
                    const innerSubscription = new Subscription();

                    scheduleRefresh();

                    function scheduleRefresh() {
                      const { refreshInfo } = lastBundle;
                      const heimdallExtraTrace = notificationReference;
                      notificationReference = '';

                      if (!refreshInfo) return;

                      const { expiresBy, lifetimeBy } = refreshInfo;

                      const refreshTimeMs = immediate
                        ? 0
                        : refreshTimeMsFor(
                            {
                              expiresByMs: expiresBy,
                              nowAtServerMs: estimatedNowAtServerMs(),
                              refreshMarginMs: expireByRefreshMarginMs(),
                              minRefreshTimeMs: 25_000,
                              debugRefreshOverrideMs:
                                getDebugRefreshOverrideMs(owner),
                            },
                            owner
                          );

                      if (refreshTimeMs < 0) {
                        // eslint-disable-next-line no-console
                        console.warn(
                          `[HEIMDALL] Token for ${owner} expired on ${new Date(
                            expiresBy
                          ).toLocaleString()}] -- unable to refresh.`
                        );
                        return;
                      }

                      const refreshScheduledOn = new Date();

                      immediate = false;

                      innerSubscription.add(
                        timer(refreshTimeMs)
                          .pipe(
                            switchMap(() => {
                              if (
                                lifetimeBy &&
                                lifetimeBy < estimatedNowAtServerMs()
                              ) {
                                throw new Error(
                                  `Lifetime exceeded for ${owner} on ${new Date(
                                    lifetimeBy
                                  ).toLocaleString()}`
                                );
                              }

                              if (expiresBy < estimatedNowAtServerMs()) {
                                // eslint-disable-next-line no-console
                                console.warn(
                                  `[HEIMDALL] Token for ${owner} expired on ${new Date(
                                    expiresBy
                                  ).toLocaleString()} -- unable to execute refresh scheduled on ${refreshScheduledOn.toLocaleString()}.`
                                );
                                return of(bundle);
                              }

                              if (!isEndpointRefreshInfo(refreshInfo)) {
                                if (isIdpBundle(lastBundle)) {
                                  const { idpConfig } = lastBundle;

                                  return getTeamsToken$().pipe(
                                    map((nativeToken) => {
                                      return packNativeTokenBundleIdp(
                                        idpConfig,
                                        nativeToken
                                      );
                                    })
                                  );
                                }

                                // eslint-disable-next-line no-console
                                console.warn(
                                  `[HEIMDALL] ${owner} refresh non IDP token with no refresh endpoint info -- giving up`
                                );
                                return of(bundle);
                              }

                              const { tokenEndpoint, refreshToken } =
                                refreshInfo;

                              const isHeimdallCall = owner === 'heimdall';

                              return createRetryFetch$<TokenSet>(
                                tokenEndpoint,
                                {
                                  method: 'POST',
                                  mode: 'cors',
                                  body: new URLSearchParams({
                                    grant_type: 'refresh_token',
                                    refresh_token: refreshToken,
                                  }),
                                  addHeimdallTrace: isHeimdallCall,
                                  logLocally: isHeimdallCall,
                                  heimdallExtraTrace,
                                  dpopToken:
                                    owner === 'heimdall' && dpopMode
                                      ? refreshToken
                                      : '',
                                }
                              ).pipe(
                                switchMap((set) => {
                                  if (isHeimdallTokenSet(set)) {
                                    return of(
                                      packTokenBundleHeimdall(
                                        tokenEndpoint,
                                        set
                                      )
                                    );
                                  }

                                  if (isIdpBundle(bundle)) {
                                    return of(
                                      packWebTokenBundleIdp(
                                        tokenEndpoint,
                                        set,
                                        bundle.idpConfig
                                      )
                                    );
                                  }

                                  throw new Error(
                                    `Unable to refresh token set${
                                      'status' in set
                                        ? `-- response status: ${set.status}`
                                        : ''
                                    }`,
                                    { cause: set }
                                  );
                                })
                              );
                            })
                          )
                          .subscribe({
                            next: (n) => {
                              lastBundle = n;
                              o.next(n);
                            },
                            error: (e) => o.error(e),
                            complete: scheduleRefresh,
                          })
                      );
                    }
                    return innerSubscription;
                  });

                  return instance$;
                }, tokenBundle),
                startWith(tokenBundle)
              )
              .subscribe(o)
          );

          return subscription;
        });
      })
    );
}

export function refreshTimeMsFor(
  {
    nowAtServerMs,
    expiresByMs,
    refreshMarginMs,
    minRefreshTimeMs,
    debugRefreshOverrideMs,
  }: {
    nowAtServerMs: number;
    expiresByMs: number;
    refreshMarginMs: number;
    minRefreshTimeMs: number;
    debugRefreshOverrideMs?: number;
  },
  owner: TokenOwner
) {
  const timeRemainingMs = expiresByMs - nowAtServerMs;

  if (timeRemainingMs < minRefreshTimeMs) return -1;

  const refreshTimeMs = Math.floor(
    Math.max(minRefreshTimeMs, timeRemainingMs - refreshMarginMs)
  );

  const refreshTimeOverrideMs = Math.floor(
    Math.max(
      debugRefreshOverrideMs !== undefined
        ? Math.max(minRefreshTimeMs, debugRefreshOverrideMs)
        : 0
    )
  );

  // eslint-disable-next-line no-console
  console.info(
    `[HEIMDALL] %cSet token refresh timer for [${owner}] ${msToMin(
      refreshTimeMs
    )} ${
      refreshTimeOverrideMs
        ? `...  overriden to ${msToMin(refreshTimeOverrideMs)}`
        : ''
    }`,
    'background: ivory; padding: 5px;'
  );

  return refreshTimeOverrideMs || refreshTimeMs;
}

export function packTokenBundleHeimdall(
  tokenEndpoint: string,
  tokenSet: TokenSetHeimdall
): TokenBundleHeimdall {
  const expiresBy = minHeimdallExpires(tokenSet);
  return {
    tokenSet: tokenSet,
    refreshInfo: {
      tokenEndpoint,
      refreshToken: tokenSet.refresh_token,
      expiresBy,
      ...(tokenSet.lifetime && { lifetimeBy: tokenSet.lifetime * 1000 }),
    },
  };
}

export function minHeimdallExpires(tokenSet: TokenSetHeimdall) {
  const expiresBy =
    estimatedNowAtServerMs() -
    (estimatedNowAtServerMs() % 1000) +
    tokenSet.expires_in * 1000;

  return Object.entries(tokenSet.access_token).reduce(
    (acc, [maybeAudience, jwtToken]) => {
      if (!isAudience(maybeAudience)) {
        return acc;
      }

      try {
        const decoded = decodeJwtPayload(jwtToken);
        if (decoded.exp) {
          return Math.min(decoded.exp * 1000, acc);
        }
      } catch {
        // No worries, just skip
      }

      return acc;
    },
    expiresBy
  );
}
