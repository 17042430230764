const DB_NAME = 'heimdall-db';
const DB_TABLE_NAME = 'heimdall-store';
const DB_VERSION = 1;
let isOpen = false;
let dbInstance: IDBDatabase | undefined;

interface IDBOpenDBRequestEvent extends Event {
  target: IDBOpenDBRequest & EventTarget;
}

interface IDBOpenOnUpgradeNeededEvent extends IDBVersionChangeEvent {
  target: IDBOpenDBRequest & EventTarget;
}

interface IDBRequestEvent extends Event {
  target: IDBRequest & EventTarget;
}

async function openDb(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (dbInstance && isOpen) resolve();

    const db = window.indexedDB.open(DB_NAME, DB_VERSION);
    db.addEventListener('upgradeneeded', (e: IDBVersionChangeEvent) => {
      const event = e as IDBOpenOnUpgradeNeededEvent;
      event.target.result.createObjectStore(DB_TABLE_NAME);
    });
    db.addEventListener('success', (e: Event) => {
      const event = e as IDBOpenDBRequestEvent;
      dbInstance = event.target.result;
      isOpen = true;
      resolve();
    });
    db.addEventListener('error', (error) => reject(error));
  });
}

function closeDb(): void {
  if (dbInstance && isOpen) {
    dbInstance.close();
    isOpen = false;
  }
}

async function getItemFromDb<T>(key: string): Promise<T | null> {
  await openDb();
  return new Promise<T>((resolve, reject) => {
    if (!dbInstance) {
      return reject('no dbInstance');
    }
    const transaction = dbInstance.transaction([DB_TABLE_NAME], 'readonly');
    const objectStore = transaction.objectStore(DB_TABLE_NAME);
    const dbGet = objectStore.get(key);

    dbGet.addEventListener('success', (e: Event) => {
      const event = e as IDBRequestEvent;
      closeDb();
      resolve(event.target.result);
    });

    dbGet.addEventListener('error', (e: Event) => {
      closeDb();
      reject(e);
    });
  });
}

async function setItemToDb(key: string, payload: unknown): Promise<void> {
  await openDb();
  return new Promise<void>((resolve, reject) => {
    if (!dbInstance) {
      return reject('no dbInstance');
    }
    const transaction = dbInstance.transaction([DB_TABLE_NAME], 'readwrite');

    const objectStore = transaction.objectStore(DB_TABLE_NAME);

    const dbPut = objectStore.put(payload, key);

    dbPut.addEventListener('success', () => {
      closeDb();
      resolve();
    });

    dbPut.addEventListener('error', (e) => {
      closeDb();
      reject(e);
    });
  });
}

async function deleteItemFromDb(key: string): Promise<void> {
  await openDb();
  return new Promise<void>((resolve, reject) => {
    if (!dbInstance) {
      return reject('no dbInstance');
    }
    const transaction = dbInstance.transaction([DB_TABLE_NAME], 'readwrite');

    const objectStore = transaction.objectStore(DB_TABLE_NAME);

    const dbDelete = objectStore.delete(key);

    dbDelete.addEventListener('success', () => {
      closeDb();
      resolve();
    });

    dbDelete.addEventListener('error', (e) => {
      closeDb();
      reject(e);
    });
  });
}

export { getItemFromDb, setItemToDb, deleteItemFromDb };
