import { of } from 'rxjs';
import { ENVS, Env } from './internal.js';
import { getLocalStorageValue } from './local-storage.js';
import { shareLast } from './rx-helpers.js';

function readEnv(): Env | undefined {
  const storedEnv = getLocalStorageValue('heimdall:env') as Env;
  return ENVS.includes(storedEnv) ? storedEnv : undefined;
}

export const env$ = of(readEnv()).pipe(shareLast);
