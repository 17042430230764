import {
  MonoTypeOperatorFunction,
  NEVER,
  ReplaySubject,
  distinctUntilChanged,
  map,
  merge,
  of,
  share,
  startWith,
  switchMap,
  tap,
  throttleTime,
  Observable,
  firstValueFrom,
} from 'rxjs';
import { createDistributedObservable$ } from './distributed-observable.js';
import {
  TokenBundle,
  TokenBundleIdp,
  TokenSetIdp,
  TokenSetRefreshable,
  isIdpBundle,
} from './internal.js';
import {
  loadStructure,
  removeLocalStorageValue,
  saveStructure,
} from './local-storage.js';
import { logoutOnError } from './logout.js';
import {
  flog,
  localStorageChange$,
  quickOrderedCompare,
} from './rx-helpers.js';
import { keepTokenFresh } from './token-refresh.js';

export type TokenSetIdpRefreshable = TokenSetIdp & TokenSetRefreshable;

export const idpTokenBundle$ =
  createDistributedObservable$<TokenBundleIdp | null>(
    'heimdall:idp',
    (o, interest$) => {
      return merge(
        localStorageChange$('heimdall:token:idp'),
        interest$ || NEVER
      )
        .pipe(
          throttleTime(10),
          startWith('something'),
          switchMap(() => {
            return fromStorageWithCompatibilityAdjustments().pipe(
              keepTokenFresh('idp', false),
              map((bundle) => {
                if (!bundle) return bundle;
                assertIsIdpBundle(bundle);
                return bundle;
              }),
              logoutOnError<TokenBundleIdp | null>(null),
              persistIdpTokenBundle()
            );
          })
        )
        .subscribe(o);
    }
  ).pipe(
    distinctUntilChanged(quickOrderedCompare),
    share({
      connector: () => new ReplaySubject(1),
      resetOnError: true,
      resetOnComplete: true,
      resetOnRefCountZero: true,
    }),
    flog(`Idp token bundle`)
  );

export const idpToken$: Observable<TokenSetIdp | null> = idpTokenBundle$.pipe(
  map((tokenBundle) => {
    if (!tokenBundle) return null;
    const { access_token, id_token } = tokenBundle.tokenSet;
    return {
      access_token,
      id_token,
    };
  })
);
export const getIdpToken = async () => firstValueFrom(idpToken$);

export function assertIsIdpBundle(
  tokenBundle: TokenBundle
): asserts tokenBundle is TokenBundleIdp {
  if (!isIdpBundle(tokenBundle)) {
    throw new Error('Incompatible Idp Bundle shape');
  }

  if (!('idpConfig' in tokenBundle)) {
    throw new Error('Idp config missing from Idp Bundle shape');
  }
}

export function persistIdpTokenBundle(): MonoTypeOperatorFunction<TokenBundleIdp | null> {
  return (source$) =>
    source$.pipe(
      tap((bundle) => {
        if (bundle) saveStructure('heimdall:token:idp', bundle);
        else removeLocalStorageValue('heimdall:token:idp');
      })
    );
}

function fromStorageWithCompatibilityAdjustments() {
  const stored = loadStructure('heimdall:token:idp') || null;
  if (stored && !stored.idpConfig.defaultAudiences) {
    stored.idpConfig.defaultAudiences = ['api.ws'];
  }
  return of(stored);
}
