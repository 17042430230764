import {
  getLocalStorageValue,
  alignLocalStorageValue,
  removeLocalStorageValue,
} from './local-storage.js';

export function getVersionContext(): string {
  return getLocalStorageValue('heimdall:version-context') || '';
}

export function setVersionContext(context: string) {
  alignLocalStorageValue('heimdall:version-context', context);
}

export function clearVersionContext() {
  removeLocalStorageValue('heimdall:version-context');
}
