const ALGORITHM_NAME = 'RSASSA-PKCS1-v1_5';
const ALGORITHM_HASH = 'SHA-256';
const MODULUS_LENGTH = 2048;
const PUBLIC_EXPONENT: Uint8Array = new Uint8Array([0x01, 0x00, 0x01]);

export async function generateCryptoKeyPair(): Promise<CryptoKeyPair> {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: ALGORITHM_NAME,
      modulusLength: MODULUS_LENGTH,
      publicExponent: PUBLIC_EXPONENT,
      hash: ALGORITHM_HASH,
    },
    true,
    ['sign']
  );
  const privateKeyJwk: JsonWebKey = await exportJwk(keyPair.privateKey);
  const unextractablePrivateKey: CryptoKey = await importJwk(
    privateKeyJwk,
    false
  );
  keyPair.privateKey = unextractablePrivateKey;

  return keyPair;
}

export async function exportJwk(key: CryptoKey) {
  return window.crypto.subtle.exportKey('jwk', key);
}

export async function signWithKey(key: CryptoKey, dataTosign: string) {
  return window.crypto.subtle.sign(
    ALGORITHM_NAME,
    key,
    new TextEncoder().encode(dataTosign)
  );
}

export async function sha256(text: string): Promise<string> {
  const msgUint8 = new TextEncoder().encode(text);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return String.fromCharCode.apply(null, hashArray);
}

async function importJwk(
  key: JsonWebKey,
  extractable: boolean
): Promise<CryptoKey> {
  return window.crypto.subtle.importKey(
    'jwk',
    key,
    {
      name: ALGORITHM_NAME,
      hash: ALGORITHM_HASH,
    },
    extractable,
    ['sign']
  );
}
