import { TokenAudience, TokenBundleIdp, TokenOwner } from './internal.js';

type TokenAudienceStoragePrefix = 'heimdall:token:audience';
export type TokenAudienceStorageKey =
  `${TokenAudienceStoragePrefix}:${TokenAudience}`;

type TokenStructureMap = {
  'heimdall:token:idp': TokenBundleIdp;
  'heimdall:token:idp:transient': TokenBundleIdp;
};

type TokenOwnerRefreshOverride =
  `heimdall:debug:refresh-interval-override:${TokenOwner}`;

type TokenStructureKey = keyof TokenStructureMap;

export type LocalStorageKey =
  | 'heimdall:active-audiences'
  | 'heimdall:code-verifier'
  | 'heimdall:debug-log-pattern'
  | 'heimdall:heimdall-token-endpoint'
  | 'heimdall:heimdall-token-logout-uri'
  | 'heimdall:idp-end-session-endpoint'
  | 'heimdall:preferred-idp'
  | 'heimdall:redirect-uri'
  | 'heimdall:swap-token-type'
  | 'heimdall:env'
  | 'heimdall:client-type'
  | 'heimdall:link-origin-key'
  | 'heimdall:dpop-mode'
  | 'heimdall:version-context'
  | 'heimdall:observed-server-drift-milliseconds'
  | TokenOwnerRefreshOverride
  | TokenStructureKey;

export function loadStructure<K extends TokenStructureKey>(
  key: K
): TokenStructureMap[K] | undefined {
  const serialized = getLocalStorageValue(key);
  if (!serialized) return undefined;
  return JSON.parse(serialized);
}

export function saveStructure<K extends TokenStructureKey>(
  key: K,
  structure: TokenStructureMap[K]
) {
  alignLocalStorageValue(key, JSON.stringify(structure));
}

export function getPreferredIdpFromLocalStorage(): string | null {
  return getLocalStorageValue('heimdall:preferred-idp');
}

export function clearPreferredIdp() {
  removeLocalStorageValue('heimdall:preferred-idp');
}

export function getDebugRefreshOverrideMs(
  owner: TokenOwner
): number | undefined {
  const storedOverride = getLocalStorageValue(
    `heimdall:debug:refresh-interval-override:${owner}`
  );

  if (!storedOverride) return undefined;
  const parsedOverride = parseInt(storedOverride);
  if (Number.isNaN(parsedOverride)) return undefined;
  return parsedOverride;
}
export function getLogDebugPattern() {
  return getLocalStorageValue('heimdall:debug-log-pattern');
}

export function alignLocalStorageValue(
  key: LocalStorageKey,
  value: string | undefined
) {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    removeLocalStorageValue(key);
  }
}

export function updateLocalStorageValue(
  key: LocalStorageKey,
  updateFn: (current: string | null) => string
) {
  const currentValue = getLocalStorageValue(key);
  const updatedValue = updateFn(currentValue);
  alignLocalStorageValue(key, updatedValue);
}

export function getLocalStorageValue(key: LocalStorageKey) {
  return localStorage.getItem(key);
}

export function removeLocalStorageValue<K extends string = LocalStorageKey>(
  key: K
) {
  localStorage.removeItem(key);
}
