export type TokenSetIdp = { access_token?: string; id_token?: string };
export type SwapTokenType = keyof TokenSetIdp;
export type IdpConfig = {
  idpKey: string;
  clientUid: string;
  tokenEndpoint?: string;
  heimdallTokenEndpoint: string;
  swapTokenType: SwapTokenType;
  dpopMode: DpopMode;
  defaultAudiences: TokenAudience[];
};

export type RefreshInfo = {
  expiresBy: number;
  lifetimeBy?: number;
};

export type EndpointRefreshInfo = RefreshInfo & {
  tokenEndpoint: string;
  refreshToken: string;
};

export function isEndpointRefreshInfo(
  info: RefreshInfo | EndpointRefreshInfo
): info is EndpointRefreshInfo {
  if (!('tokenEndpoint' in info) || !('refreshToken' in info)) {
    return false;
  }

  return (
    typeof info.tokenEndpoint === 'string' &&
    typeof info.refreshToken === 'string'
  );
}

export type DpopMode = 'supported' | 'required' | undefined;
export type ClientType = 'workspace' | 'teams';

export type TokenSetRefreshable = {
  refresh_token: string;
  expires_in: number;
  lifetime?: number;
};

export function isRefreshableTokenSet(
  tokenSet: unknown
): tokenSet is TokenSet & TokenSetRefreshable {
  if (
    !('refresh_token' in (tokenSet as TokenSetRefreshable)) ||
    !('expires_in' in (tokenSet as TokenSetRefreshable))
  ) {
    return false;
  }

  return (
    typeof (tokenSet as TokenSetRefreshable).expires_in === 'number' &&
    typeof (tokenSet as TokenSetRefreshable).refresh_token === 'string'
  );
}

export function assertIsRefreshableTokenSet(
  tokenSet: TokenSet
): asserts tokenSet is TokenSet & TokenSetRefreshable {
  if (!isRefreshableTokenSet(tokenSet))
    throw new Error('Token set is not refreshable');
}

export type TokenBundleIdp = {
  refreshInfo: RefreshInfo | EndpointRefreshInfo;
  tokenSet: TokenSetIdp;
  idpConfig: IdpConfig;
  ref?: string;
};

export type TokenBundleHeimdall = {
  tokenSet: TokenSetHeimdall;
  refreshInfo: EndpointRefreshInfo;
};

export type MaybeHeimdallBundle = TokenBundleHeimdall | undefined;

export type LocalFields = {
  codeVerifier: string;
  redirectUri: string;
  heimdallTokenEndpoint: string;
  dpopMode: DpopMode;
  clientType: ClientType;
  swapTokenType?: SwapTokenType;
  idpEndSessionEndpoint?: string;
  heimdallTokenLogoutUri?: string;
};

export type TokenAudience =
  | 'api.ws'
  | 'connect.ws'
  | 'data.ws'
  | 'restricted.ws'
  | 'thirdparty.ws';

export type TokenSetHeimdallOk = {
  status: 'ok';
};

export type TokenSetHeimdall = TokenSetHeimdallOk & {
  access_token: TokenByAudience;
} & TokenSetRefreshable;

export type TokenSetHeimdallStateless = TokenSetHeimdallOk & {
  access_token: string;
};

export type TokenOwner = 'idp' | 'heimdall';

export type TokenUpdate = { token: string; expiresBy: number };
export type TokenUpdateData = TokenUpdate & { isRealTime: boolean };

export type TokenUpdateMap = {
  'api.ws': TokenUpdate;
  'connect.ws': TokenUpdate;
  'data.ws': TokenUpdateData;
  'restricted.ws': TokenUpdate;
  'thirdparty.ws': TokenUpdate;
};

export type TokenByAudience = Partial<Record<TokenAudience, string>>;

export type BaseConfig = {
  idpKey: string;
  clientUid: string;
  clientId: string;
  nextPath: string;
};

export type RedirectionStateFields = BaseConfig & {
  tokenEndpoint: string;
  stateNonce: string;
};

export const audiences: TokenAudience[] = [
  'api.ws',
  'data.ws',
  'restricted.ws',
  'thirdparty.ws',
  'connect.ws',
];

export type TokenSetHeimdallResult =
  | TokenSetHeimdall
  | TokenSetHeimdallStateless
  | { status: 'device_conflict' | 'invalidated' };

export function assertIsHeimdallBundle(
  tokenBundle: unknown
): asserts tokenBundle is TokenBundleHeimdall {
  if (!isHeimdallBundle(tokenBundle)) {
    throw new Error('Incorrect Heimdall Bundle shape');
  }
}

export function isHeimdallBundle(
  maybeBundle: unknown
): maybeBundle is TokenBundleHeimdall {
  return isHeimdallTokenSet((maybeBundle as TokenBundleHeimdall).tokenSet);
}

export function isHeimdallTokenSet(
  maybeHeimdallTokenSet: unknown
): maybeHeimdallTokenSet is TokenSetHeimdall {
  return (
    isHeimdallOkTokenSet(maybeHeimdallTokenSet as TokenSetHeimdall) &&
    typeof (maybeHeimdallTokenSet as TokenSetHeimdall).access_token === 'object'
  );
}

export function assertIsHeimdallOkTokenSet(
  maybeOkTokenSet: unknown
): asserts maybeOkTokenSet is TokenSetHeimdallOk {
  if (!isHeimdallOkTokenSet(maybeOkTokenSet as TokenSetHeimdallOk)) {
    throw new Error('Incorrect Heimdall Token');
  }
}

export function isHeimdallOkTokenSet(
  set: TokenSet | TokenSetHeimdallResult | TokenSetHeimdallOk
): set is TokenSetHeimdallOk {
  return 'status' in set && set.status === 'ok';
}

export function assertIsHeimdallTokenSet(
  maybeHeimdallTokenSet: unknown
): asserts maybeHeimdallTokenSet is TokenSetHeimdall {
  if (!isHeimdallTokenSet(maybeHeimdallTokenSet)) {
    throw new Error('Incorrect Heimdall Token');
  }
}
export type TokenBundle = TokenBundleHeimdall | TokenBundleIdp;
export type TokenSet =
  | TokenSetHeimdall
  | TokenSetIdp
  | TokenSetHeimdallStateless;

export const stepUpResourceId = 'heimdall:step:check';

export function isAudience(
  maybeAudience: string
): maybeAudience is TokenAudience {
  return audiences.includes(maybeAudience as TokenAudience);
}

export function isIdpBundle(
  tokenBundle: TokenBundle
): tokenBundle is TokenBundleIdp {
  if (!('idpConfig' in tokenBundle)) return false;

  const { tokenSet, idpConfig } = tokenBundle;

  if (!tokenSet || !idpConfig) return false;

  const { defaultAudiences } = idpConfig;

  if (!Array.isArray(defaultAudiences)) return false;

  if ('access_token' in tokenSet) {
    return typeof tokenSet.access_token === 'string';
  }
  return 'id_token' in tokenSet && typeof tokenSet.id_token === 'string';
}

export const ENVS = ['dev', 'qa', 'ppe', 'prod'] as const;
export type Env = (typeof ENVS)[number];

export const audienceResourcePrefix = 'heimdall:audience:';

export type AuthState =
  | 'LOGGED_IN'
  | 'LOGGING_IN'
  | 'LOGGED_OUT'
  | 'LOGIN_EXPIRED';

export function defaultAudiencesByClientType(
  clientType: ClientType
): TokenAudience[] {
  return clientType == 'workspace' ? ['api.ws', 'data.ws'] : ['api.ws'];
}
