import { firstValueFrom, map } from 'rxjs';
import { acquireToken$ } from './token-audience.js';
import { forceStepUp$ } from './token-set.js';
export { idpToken$, getIdpToken } from './idp';
export { authState$ } from './auth-state.js';
export { generateDpopHeader } from './dpop.js';
export type {
  AuthState,
  ClientType,
  DpopMode,
  SwapTokenType,
  TokenAudience,
  TokenBundleIdp,
  TokenSetHeimdall,
  TokenSetIdp,
  TokenUpdate,
  TokenUpdateData,
} from './internal.js';
export { logOut, optOut } from './logout.js';

export const apiToken$ = acquireToken$('api.ws');
export const connectToken$ = acquireToken$('connect.ws');
export const dataToken$ = acquireToken$('data.ws');
export const restrictedToken$ = acquireToken$('restricted.ws');
export const thirdpartyToken$ = acquireToken$('thirdparty.ws');

export const getApiToken = async () => firstValueFrom(apiToken$);
export const getConnectToken = async () => firstValueFrom(connectToken$);
export const getDataToken = async () => firstValueFrom(dataToken$);
export const getRestrictedToken = async () => firstValueFrom(restrictedToken$);
export const getThirdpartyToken = async () => firstValueFrom(thirdpartyToken$);

export const token$ = apiToken$.pipe(map(({ token }) => token));
export const getToken = async () => firstValueFrom(token$);

export const claimRealTimeData = () => forceStepUp$.next('data.ws');
