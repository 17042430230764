/* eslint-disable no-console */
import {
  EMPTY,
  MonoTypeOperatorFunction,
  Observable,
  ObservableInput,
  ReplaySubject,
  Subscription,
  catchError,
  defer,
  filter,
  from,
  fromEvent,
  map,
  noop,
  retry,
  share,
  switchMap,
  tap,
  timer,
} from 'rxjs';

import { fromFetch } from 'rxjs/fetch';
import { generateDpopHeaderIfRequired$ } from './dpop.js';
import {
  estimatedNowAtServerMs,
  estimatedServerDeltaMs,
  observedServerTimeMs,
} from './estimated-now-at-server.js';
import { LocalStorageKey, getLogDebugPattern } from './local-storage.js';
import { VERSION } from './metadata.js';
import { getVersionContext } from './version-context.js';

const recoverableClientErrors = new Set([
  408, // Request Timeout
  409, // Conflict
  423, // Locked
  429, // Too Many Requests
]);

export function log<T>(label: string, style?: string) {
  if (style) {
    return {
      next: ifMatches(label, (n: T) =>
        console.log(`%c[NXT ${label}]`, style, n)
      ),
      error: ifMatches(label, (e: unknown) =>
        console.warn(`%c[ERR ${label}]`, style, e)
      ),
      complete: ifMatches(label, () => console.log(`%c[COM ${label}]`, style)),
    };
  } else {
    return {
      next: ifMatches(label, (n: T) => console.log(`[NXT ${label}]`, n)),
      error: ifMatches(label, (e: unknown) =>
        console.warn(`[ERR ${label}]`, e)
      ),
      complete: ifMatches(label, () => console.log(`[COM ${label}]`)),
    };
  }
}

export function flog<T>(
  label: string,
  style?: string,
  tickMs?: number
): MonoTypeOperatorFunction<T> {
  return function flog(source$) {
    const logDestroy = ifMatches(label, () => {
      if (style) {
        console.log(`%c[DXR ${label}]`, style);
      } else {
        console.log(`[DXR ${label}]`);
      }
    });

    const logCreation = ifMatches(label, () => {
      if (style) {
        console.log(`%c[CRT ${label}]`, style);
      } else {
        console.log(`[CRT ${label}]`);
      }
    });

    return new Observable<T>((o) => {
      let tickId: number;
      let c = 0;

      logCreation();

      const tickLog = ifMatches(label, () => {
        if (style) {
          console.log(`%c[⏱ ${label} ${formatId(tickId)}·${c++}]`, style);
        } else {
          console.log(`[⏱ ${label} ${formatId(tickId)}·${c++}]`);
        }
      });

      if (tickMs) {
        tickId = window.setInterval(tickLog, tickMs);
        tickLog();
      }

      const subscription = new Subscription();
      subscription.add(source$.pipe(tap(log(label, style))).subscribe(o));
      return () => {
        if (tickId) {
          clearInterval(tickId);
        }
        subscription.unsubscribe();
        logDestroy();
      };
    });
  };
}

export function shareLast<T>(source$: Observable<T>): Observable<T> {
  return source$.pipe(
    share({
      connector: () => new ReplaySubject(1),
      resetOnError: false,
      resetOnComplete: false,
      resetOnRefCountZero: true,
    })
  );
}

export type RetryConfig = {
  count?: number;
  ditherMin?: number;
  ditherMax?: number;
  backOffBase?: number;
  retryClientErrors?: boolean;
};

export function createRetryFetch$<T>(
  input: string | Request,
  requestInit: RequestInit & {
    addHeimdallTrace?: boolean;
    heimdallExtraTrace?: string;
    skipParse?: boolean;
    dpopToken?: string;
    syncToDateHeader?: boolean;
    logLocally?: boolean;
  } = {},
  retryConfig: RetryConfig = {}
): Observable<T> {
  const {
    addHeimdallTrace,
    heimdallExtraTrace,
    skipParse,
    headers,
    dpopToken,
    syncToDateHeader = false,
    logLocally = false,
    ...init
  } = requestInit;

  const targetUrl = typeof input === 'string' ? input : input.url;
  const httpMethod = init.method || 'GET';
  const correlationId = addHeimdallTrace
    ? `${
        heimdallExtraTrace ? `${heimdallExtraTrace}.` : ''
      }H:${createNonce()}${noteSignificantTimeDrifts()}`
    : undefined;

  return defer(() => {
    return generateDpopHeaderIfRequired$(
      !!dpopToken,
      targetUrl,
      httpMethod,
      dpopToken
    ).pipe(
      switchMap((dpop) => {
        const versionContext = getVersionContext();
        const clientVersion = `${VERSION}${
          versionContext ? `:${versionContext}` : ''
        }`.slice(0, 24);

        const requestHeaders = {
          ...headers,
          ...(correlationId
            ? {
                'X-Heimdall-Trace': correlationId,
                'X-Heimdall-Client': clientVersion,
              }
            : {}),
          ...(dpop ? { dpop } : {}),
        };

        if (logLocally) {
          // eslint-disable-next-line no-console
          console.info(
            `[HEIMDALL] %cCall ${correlationId} estimated ${new Date(
              estimatedNowAtServerMs()
            ).toISOString()} local ${new Date().toISOString()} ${clientVersion}`,
            'background: aliceblue; padding: 5px;'
          );
        }

        return fromFetch<T>(input, {
          ...init,
          headers: requestHeaders,
          selector: okSelector<T>(skipParse, syncToDateHeader),
        }).pipe(
          tap((r: T) => {
            if (hasTime(r)) {
              observedServerTimeMs(r.time * 1000);
            }
          })
        );
      })
    );
  }).pipe(
    ditheredRetry(retryConfig),
    flog(`Fetch JSON ${JSON.stringify(input)}`),
    catchError(createServerResponseErrorHandler(correlationId))
  );
}

class NonRecoverableClientError extends Error {}

export class ServerResponseError extends Error {
  constructor(
    message: string,
    type: string | undefined,
    correlationId: string | undefined
  ) {
    super(message);
    this.type = type;
    this.correlationId = correlationId;
  }

  type?: string;
  correlationId?: string;
}

export function okSelector<T>(skipParse = false, syncToDateHeader = false) {
  return function (response: Response): ObservableInput<T> {
    const { ok, url, statusText, status, headers } = response;
    if (syncToDateHeader && headers) {
      const dateHeader = headers.get('date');
      if (dateHeader) {
        const serverTime = new Date(dateHeader).getTime();
        if (!isNaN(serverTime)) {
          observedServerTimeMs(serverTime);
        }
      }
    }

    if (status >= 400 && status < 500 && !recoverableClientErrors.has(status)) {
      throw new NonRecoverableClientError(
        `Non-recoverable client error ${status} for ${url}: ${statusText}`,
        { cause: response }
      );
    }

    if (!ok) {
      throw new Error(`Error not-ok loading ${url}: ${statusText} ${status}`, {
        cause: response,
      });
    }

    return skipParse ? EMPTY : response.json();
  };
}

export function ditheredRetry<T>({
  count = 3,
  ditherMin = 500,
  ditherMax = 2500,
  backOffBase = 2.5,
  retryClientErrors = false,
}: RetryConfig = {}): MonoTypeOperatorFunction<T> {
  const retryPause = dither(ditherMin, ditherMax);
  return function (source$) {
    return source$.pipe(
      retry({
        count,
        delay: (error: unknown, retryCount: number) => {
          const isNonRecoverableClientError =
            error instanceof NonRecoverableClientError;

          if (isNonRecoverableClientError && !retryClientErrors) {
            throw error;
          }

          const backOff = Math.pow(backOffBase, retryCount - 1);
          const ditheredPause = retryPause();
          const delay = ditheredPause * backOff;
          console.warn(
            `Retry ${retryCount} back-off ${backOff} × dithered ${msToMin(
              ditheredPause
            )} = ${msToMin(delay)} for:`,
            error
          );
          return timer(delay);
        },
      })
    );
  };
}

export function quickFieldOrderedCompare<T>(fields: Array<keyof T>) {
  return function compare(a: T | undefined, b: T | undefined) {
    if (!a || !b) return a === b;
    const ta = fields.reduce(from(a), {});
    const tb = fields.reduce(from(b), {});
    return quickOrderedCompare(ta, tb);
  };

  function from(x: T) {
    return function (acc: Partial<T>, k: keyof T) {
      acc[k] = x[k];
      return acc;
    };
  }
}

export function quickOrderedCompare<T>(a: T, b: T) {
  const aa = String(JSON.stringify(a));
  const bb = String(JSON.stringify(b));
  return aa === bb;
}

function ifMatches<U>(label: string, fn: U): U | (() => void) {
  const pattern = getLogDebugPattern();
  if (!pattern) return noop;
  const expression = new RegExp(pattern);
  return expression.test(label) ? fn : noop;
}

export function dither(min = 0, max = 1) {
  return function () {
    return Math.random() * (max - min) + min;
  };
}

export function msToMin(ms: number) {
  const minutes = ms / (1000 * 60);
  return `${Math.floor(minutes)}m ${Math.round((minutes % 1) * 60)}s`;
}

function formatId(id: number) {
  return id.toString(32).toUpperCase();
}

export function notifySubscriptionChange<T>({
  on,
  off,
}: {
  on?: () => void;
  off?: () => void;
}): MonoTypeOperatorFunction<T> {
  return (i) => {
    const ok = new Observable<T>((o) => {
      const subscription = new Subscription();
      on && on();
      i.subscribe(o);
      return () => {
        subscription.unsubscribe();
        off && off();
      };
    });
    return ok;
  };
}

export function chainOps<T>(
  operations: MonoTypeOperatorFunction<T>[]
): MonoTypeOperatorFunction<T> {
  return (source$) => {
    return operations.reduce((s$, nextOp) => s$.pipe(nextOp), source$);
  };
}

export function createNonce() {
  return Math.floor(Math.random() * 4294967295)
    .toString(32)
    .toUpperCase();
}

export function localStorageChange$(key: LocalStorageKey) {
  return fromEvent<StorageEvent>(window, 'storage').pipe(
    filter((e) => e.key === key),
    map((e) => {
      const { newValue, oldValue } = e;
      return { newValue, oldValue };
    }),
    flog(`Local Storage change for ${key}`)
  );
}

export function tapAsync<T>(
  fn: (value: T) => ObservableInput<unknown>
): MonoTypeOperatorFunction<T> {
  return (source$: Observable<T>): Observable<T> =>
    source$.pipe(switchMap((value) => from(fn(value)).pipe(map(() => value))));
}

function createServerResponseErrorHandler(correlationId: string | undefined) {
  return async function handleServerResponseErrors(error: Error) {
    let errToThrow = error;
    if (error.cause instanceof Response) {
      if (error.cause.status === 400) {
        try {
          const errorResp = await error.cause.json();
          errToThrow = new ServerResponseError(
            error.message,
            errorResp.error,
            correlationId
          );
        } catch (_) {
          errToThrow = new ServerResponseError(
            error.message,
            undefined,
            correlationId
          );
        }
      } else {
        errToThrow = new ServerResponseError(
          error.message,
          undefined,
          correlationId
        );
      }
    }

    throw errToThrow;
  };
}

function hasTime(i: unknown): i is { time: number } {
  return (
    typeof i === 'object' &&
    i !== null &&
    'time' in i &&
    typeof (i as { time: unknown }).time === 'number'
  );
}

export function noteSignificantTimeDrifts() {
  const deltaMins = estimatedServerDeltaMs() / 60_000;
  if (Math.abs(deltaMins) < 0.1) {
    return '';
  }
  return `${deltaMins < 0 ? '' : '+'}${Math.round(deltaMins * 10) / 10}`;
}
