import {
  EMPTY,
  MonoTypeOperatorFunction,
  NEVER,
  catchError,
  from,
  lastValueFrom,
  of,
  switchMap,
} from 'rxjs';
import { createUpdateCdn$ } from './cdn.js';
import { removeLocalStorageValue } from './local-storage.js';
import { indicateInterest } from './distributed-interest.js';
import { clearVersionContext } from './version-context.js';

export async function logOut() {
  return terminateHeimdallSession(false);
}

export async function optOut() {
  return terminateHeimdallSession(true);
}

async function terminateHeimdallSession(includeAuthMode = false) {
  return lastValueFrom(
    createUpdateCdn$('', includeAuthMode).pipe(catchError(() => EMPTY)),
    {
      defaultValue: 'ignored 🧷',
    }
  ).then(() => {
    removeLocalStorageValue('heimdall:token:idp');
    removeLocalStorageValue('heimdall:preferred-idp');
    removeLocalStorageValue('heimdall:observed-server-drift-milliseconds');
    clearVersionContext();
    indicateInterest('heimdall:idp');
  });
}

export function logoutOnError<T>(
  fallback: T | undefined = undefined
): MonoTypeOperatorFunction<T> {
  return (source$) =>
    source$.pipe(
      catchError((e) => {
        // eslint-disable-next-line no-console
        console.warn(`Unexpected authorization error -- cleaning up`, e);
        return from(logOut()).pipe(
          switchMap(() => {
            if (fallback !== undefined) {
              return of(fallback);
            }
            return NEVER;
          })
        );
      })
    );
}
